import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prime Sets`}</h2>
    <ul>
      <li parentName="ul">{`Distinctions`}
        <ul parentName="li">
          <li parentName="ul">{`Birthright or Culture`}
            <ul parentName="li">
              <li parentName="ul">{`Culture: Aljieudic, Tharshan, Msantine`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Vocation`}</li>
          <li parentName="ul">{`Personality/Quirk/Catch Phrase`}</li>
          <li parentName="ul">{`Optional 4th distinction: Luminary Affinity:`}
            <ul parentName="li">
              <li parentName="ul">{`Faded, Lightless, and Acronist`}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Attributes`}</li>
      <li parentName="ul">{`Specialties`}</li>
      <li parentName="ul">{`Signature Assets`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      